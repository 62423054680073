import { Card } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import BackComponent from "../../components/container/backComponent/BackComponent";
import EventCardComponent from "../../components/card/EventCardComponent";
import TableComponent from "../../components/table/TableComponent";
import EventPreviewModal from "../../components/container/modal/queueModal/EventPreviewModal";
import API from '../../utils/request'
import { API_ENDPOINT, API_ENDPOINT_CSL_PLASMA, API_ENDPOINT_LOCATION } from "../../config/api";
import { EVENT_CHANGE_STATUS, EVENT_FORMSUBMISSION_EXPORT, EVENT_GET_URL } from "../../config/urls";
import { message } from "antd";
import { storageKeys } from "../../constants/storage";
import * as Storage from '../../utils/storage';
import axios from "axios";
import { CenterDetail } from "../../utils/checkCenterLocation";
import dayjs from "dayjs";
import MessageModalMain from "../../components/container/modal/modalMessage/messageModalMain";
import ExportToExcel from "../../utils/exportExcel";
import { MdOutlineRefresh } from "react-icons/md";
import ChooseWinnerComponent from "./ChooseWinnerComponent";


const EventDetailPage = () => {
    const [loading, setLoading] = useState(false);
    const messageModalRef = useRef('rememberMe');
    const location = useLocation()
    const EventPreviewModalRef = useRef("")
    const [searchParams, setSearchParams] = useSearchParams();
    const [eventId, setEventId] = useState(searchParams.get('event') ? searchParams.get('event') : '')
    const [dataItems, setDataItem] = useState(null)
    const [isEdit, setIsEdit] = useState(false);
    const [status, setStatus] = useState(true);
    const navigate = useNavigate();
    const user = useSelector((state) => state.authReducer.user);
    var users = Storage.getString(storageKeys.USER);
    let userUse = user ? user : users

    const [centerData, setCenterData] = useState([])
    const tableComponentRef = useRef()
    const winnerComponentRef = useRef();

    useEffect(() => {
        if (dataItems) {
            setIsEdit(dayjs(dataItems.endDate).isBefore(dayjs(), 'day'));
            setStatus(!dayjs(dataItems.endDate).isBefore(dayjs(), 'day') && dataItems.status === "active")
        }
    }, [dataItems])

    const getDataCenter = async () => {
        try {
            API.post(API_ENDPOINT_LOCATION, {
                "serviceName": "location",
                "serviceType": "getSavableLocations"
            })
                .then((res) => {
                    if (res.status === 200) {
                        setCenterData(res.locations ? res.locations.map(it => ({
                            ...it,
                            label: `CSL Plasma, ${it.name}`,
                            value: it.locationID
                        })) : [])
                    }
                })
                .catch((err) => {
                })
        } catch (error) {
        }
    }
    useEffect(() => {
        getDataCenter()
    }, []);

    const getDataEventDetail = async () => {
        try {
          setLoading(true)
          API.get(`${EVENT_GET_URL}/${eventId}`)
            .then((res) => {
              if (res.status === 1 && res.data) {
                let data = res.data
                setDataItem({
                    ...data,
                    _id: data._id,
                    eventId: data.eventId,
                    scanCount: data.scannedCount,
                    submissionCount: data.submissionCount,
                    eventName: data.eventName,
                    eventNameES: data.eventNameES ? data.eventNameES : "",
                    eventNumber: data.eventNumber,
                    centerName: data.appliedCenter,
                    eventCenter: data.appliedCenter,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    description: data.eventDescription ? data.eventDescription : ``,
                    eventDescriptionES: data.eventDescriptionES ? data.eventDescriptionES : ``,
                    images: data.eventGiftPicture ? data.eventGiftPicture : [],
                    formLink: `${API_ENDPOINT_CSL_PLASMA}/ShareFormPage?id=${data.formsLink && data.formsLink.id && data.formsLink.id}`
                })
              }
              setLoading(false)
            })
            .catch((err) => {
              setLoading(false)
            })
        } catch (error) {
          setLoading(false)
          message.error('Internal Server Error!')
        }
      }
      useEffect(() => {
        if(userUse && userUse?.role === "center_staff" ? userUse?.centerId : null) {
            eventId && getDataEventDetail()
        } else if(userUse && userUse?.role === "field_marketing") {
            eventId && getDataEventDetail()
        }
      }, [eventId, userUse]);

    const allowEdit = () => {
        return (dataItem && userUse && userUse?.role === "center_staff" && dataItem.eventCenter.length > 1) ? undefined : true;
    }

    const handleDetail = (item) => {
        navigate(`/event/detail?event=${item._id}`)
    }
    const handleCopy = (item) => {
        navigate(`/event/create`,
            { state: { isCopy: true, item: item }, replace: true }
        );
    }
    
    let dataItem = dataItems
    let isDashboard = true

    const handleExport = async () => {
        try {
            axios({
                url: API_ENDPOINT + EVENT_FORMSUBMISSION_EXPORT + `/${eventId}?titleCenter=${dataItem && CenterDetail(dataItem.eventCenter, centerData)}`,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json;charset=UTF-8",
                    "Authorization": `${Storage.getString(storageKeys.TOKEN)}`
                }
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Forms_Submission_${dataItem.eventName.replaceAll(" ", "_")}.xlsx`); // Specify the file name
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        } catch (error) {
        }
    }

    const handleChangeStatus = async () => {
        let newStatus = status ? "inactive" : "active";
        messageModalRef.current.showWarningConfirm(`Are you sure you want to make ${newStatus}?`, async () => {
            setLoading(true);
            await API.post(EVENT_CHANGE_STATUS + "/" + eventId,{
                "status": newStatus
            }).then(async (res) => {
                if (res.status === 1) {
                    await getDataEventDetail()
                    messageModalRef.current.showSuccess("Change status successfully", () => { }, "", true);
                } else {
                    messageModalRef.current.showWarning(res?.message);
                }
                setLoading(false);
            }).catch(err => {
                messageModalRef.current.showWarning(err?.message);
                setLoading(false)
            })
        }, "", true);
    }

    let isShow = userUse && userUse.role === "field_marketing"
    
    return <div className="width-100 m-auto h-100 pt-2-">
        <BackComponent 
        onClick={() => {
            if(location && location.state && location.state.isDashboard) {
                navigate(`/`,
                    { state: { }, replace: true }
                );
            } else {
                navigate(`/event`,
                    { state: { }, replace: true }
                );
            }
        }} 
        onClickEdit={() => {
            navigate(`/event/create?event=${dataItem.eventId}`)
        }}
        onClickChangeStatus={handleChangeStatus}
        title={"Event detail"}
        isRefresh={
            <span style={{ cursor: "pointer" }} onClick={() => {
                getDataEventDetail()
                tableComponentRef.current?.getDataSubmissions();
                winnerComponentRef.current?.fetchWinner();
            }} className="ms-2 refresh-div"><MdOutlineRefresh /></span>
        }
        titleEdit={"Event Edit"}
        isEdit={allowEdit() && isEdit}
        status={status}
        />
        
        <div className="w-100 h-100- mt-2"
        >
            <Card className="h-100 card-cus-">
                <Card.Body className="ps-0 pe-0">
                    {/* <Spin indicator={
                        <center>
                        <div className="spinner" style={{ marginBottom: '37px' }}></div>
                        <p style={{ fontWeight: '400', fontSize: '18px', width: '100%' }}>{''}</p>
                        <p style={{ fontWeight: '700', fontSize: '18px', width: '100%' }}>{''}</p>
                    </center>
                    } size="large" tip="" spinning={loading}> */}
                    {/* <Skeleton loading={loading} className="ps-2 pe-2" active paragraph={{ rows: 8, }}> */}
                    <div className="w-100 ps-3 pe-3">
                        <div className="w-100">
                            <div className="w-100">
                                <EventCardComponent
                                data={dataItem}
                                handleDetail={handleDetail}
                                handleCopy={handleCopy}
                                handlePreview={(value) => {
                                    EventPreviewModalRef.current.open(value)
                                }}
                                centerData={centerData}
                                loading={loading}
                            /></div>
                        </div>
                    </div>
                    {/* </Skeleton> */}
                    {/* </Spin> */}
                </Card.Body>
            </Card>
        </div>

        { 
            dataItem && dataItem.submissionCount > 0 &&
            <div className="w-100 mt-3">
                <Card className="h-100">
                    <Card.Body className="ps-0 pe-0">
                        <ChooseWinnerComponent ref={winnerComponentRef} eventId={eventId} />
                    </Card.Body>
                </Card>
            </div>
        }

        {isShow ? <div className="w-100 h-100- mt-3" 
        >
            <Card className="h-100 card-cus-">
                <Card.Body className="ps-0 pe-0">
                    
                    <div className="w-100 ps-3 pe-3 pt-2">
                        <div className="w-100">
                            <div className="w-100 d-flex align-items-baseline" style={{justifyContent: "space-between"}}>
                                <h5 style={{fontWeight: "600"}}>Forms Submission</h5>
                                <ExportToExcel eventDetail={dataItem} centerData={centerData} fileName="MyData"/>
                            </div>
                            <div className="w-100">
                                <TableComponent ref={tableComponentRef} eventId={eventId} dataItems={dataItems} />
                            </div>
                        </div>
                    </div>
                    
                </Card.Body>
            </Card>
        </div> : ""}

      <EventPreviewModal 
        ref={EventPreviewModalRef}
      />
      {/* {loading ? <LoadingOverlay /> : null} */}
      <MessageModalMain textCentered ref={messageModalRef} />
    </div>
}
export default EventDetailPage;