import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import { CenterDetail } from './checkCenterLocation';
import { AiOutlineExport } from 'react-icons/ai';
import API from './request'
import { EVENT_LIST_URL } from '../config/urls';
import { Button, message } from 'antd';
import dayjs from 'dayjs';
import { formatCurrency, formatNumber } from './string';

const ExportEventToExcel = ({ loadingList, getPayload, centerData }) => {
    const [loading, setLoading] = useState(false);

    const getTotalEvent = async () => {
        try {
            const res = await API.post(EVENT_LIST_URL, {
                ...getPayload(),
                getAll: true,
                sortField: "eventNumber"
            })
            if (res.status === 200) {
                return res.data
            } else {
                return [];
            }
        } catch (error) {
            message.error('Internal Server Error!')
            return [];
        }
    }

    const exportEventToExcel = async () => {
        try {
            setLoading(true);
            const eventList = await getTotalEvent();

            let displayHeaders = [];

            const headers = [
                { label: "Event Center", key: "appliedCenter" },
                { label: "Event Name", key: "eventName" },
                { label: "Event Number", key: "eventNumber" },
                { label: "Event Start Date", key: "startDate" },
                { label: "Event End Date", key: "endDate" },
                { label: "Event Cost USD", key: "eventCost" },
                { label: "Total Scanned", key: "scannedCount" },
                { label: "Total Forms Submitted", key: "submissionCount" },
                { label: "Total Over 18 Years Old", key: "legalAgeCount" },
                { label: "Total Agreed to Conditions", key: "agreementCount" },
            ];

            // Get the headers dynamically from the first item
            headers.forEach(({ label }) => {
                displayHeaders.push(label);
            })

            // Transform JSON data to match the desired layout
            const rowData = [];
            eventList.forEach((event) => {
                const record = headers.map((header) => {
                    if (header.key === "appliedCenter") {
                        return `${event.appliedCenter.reduce((accumulator, currentValue) => {
                            return accumulator + CenterDetail(currentValue, centerData) + "\n";
                        }, "")}`;
                    }

                    if (header.key === "eventCost") {
                        return formatCurrency(event[header.key]);
                    }

                    if (["scannedCount", "submissionCount", "legalAgeCount", "agreementCount"].includes(header.key)) {
                        return formatNumber(event[header.key]);
                    }

                    return event[header.key];
                });

                rowData.push(record);
            });

            // Convert the transformed data into an array of arrays for XLSX
            const wsData = [
                displayHeaders,
                ...rowData
            ];

            // Create a new workbook and ws
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.aoa_to_sheet(wsData);

            // Define styles for header cells
            const headerCellStyle = {
                font: { bold: true, color: { rgb: "FFFFFF" } }, // White text
                fill: { fgColor: { rgb: "FF0000" } }, // Red background
                alignment: { vertical: 'center', horizontal: 'center' },
                border: {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                }
            };

            // Apply styles to header cells
            displayHeaders.forEach((header, index) => {
                const cellAddress = XLSX.utils.encode_cell({ r: 6, c: index });
                if (!ws[cellAddress]) ws[cellAddress] = {};
                ws[cellAddress].s = headerCellStyle;
            });

            const wrapStyle = {
                alignment: { wrapText: true, horizontal: "center", vertical: "center" }
            };

            // Assuming wsData is your worksheet data and wrapStyle is defined
            for (let i = 1; i < wsData.length; i++) {  // Start from 1 to avoid header row
                for (let j = 0; j < wsData[i].length; j++) { // Loop through all columns
                    const cellRef = XLSX.utils.encode_cell({ r: i, c: j }); // Get cell reference
                    if (!ws[cellRef]) ws[cellRef] = {};
                    ws[cellRef].s = wrapStyle; // Apply wrap style
                }
            }

            // Apply header styles (assuming headers are in row 0)
            displayHeaders.forEach((_, index) => {
                const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
                if (!ws[cellAddress]) ws[cellAddress] = {};
                ws[cellAddress].s = headerCellStyle;
            });

            // Define column widths
            ws['!cols'] = displayHeaders.map(() => ({ wch: 35 })); // Set width for all columns

            // Set row heights
            ws['!rows'] = [
                { hpx: 30 }, // Header row height (index 0)
                ...rowData.map(() => ({ hpx: 25 })) // Apply height for all data rows
            ];

            // Append ws to workbook
            XLSX.utils.book_append_sheet(wb, ws, "FormSubmission");

            // Write the workbook and save as an Excel file
            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `Report ${dayjs().format("DD/MM/YYYY")}.xlsx`);
        } catch (error) {
            message.error('Internal Server Error!')
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            loading={loading}
            variant="primary"
            size='large'
            block
            onClick={exportEventToExcel}
            disabled={loadingList}
            className={`d-flex align-items-center`}
            style={{ borderColor: "#0d6efd", background: "none", color: "#0d6efd", fontSize: "0.8rem" }}
            icon={<AiOutlineExport/>}
        >

            <span className="ms-1">Export Report</span>
        </Button>
    );
};

export default ExportEventToExcel;