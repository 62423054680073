export function encryptParams(p) {
  return p;
}

export function formatNumber(num) {
  if(!num) {
    return 0
  }
  return num.toLocaleString('en-US');
}

export function formatCurrency(num) {
  if(!num) {
    return "N/A";
  }
  return num.toLocaleString('en-US', {style: 'currency', currency: 'USD'}).replace("$", "$ ");
}

export const CheckSortBy  = (key) => {
  switch (key) {
    case "ascend":
      return"asc"
      break;
    case "descend":
      return "desc"
      break;
  
    default:
      return ""
      break;
  }
}

export const formatFileSize = (size) => {
  if(!size) {
    return `0 MB`;
  }
  let mb = (size - 0)  / 1024 / 1024
  // return `${(mb).toFixed(2)} MB`;
  if (size < 1024) return `${size} bytes`;
  else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
  else return `${(size / 1024 / 1024).toFixed(2)} MB`;
};